import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ApiService} from '../../services/api.service';
import {NotificacionesService} from '../../services/notificaciones.service';
import {ApiConinsaService} from '../../services/apiConinsa.service';
import {PropuestaConstructor} from '../../models/propuestaConstructor';
import {origins, budget, perceptions} from './constants';
import {DataService} from '../../services/dataService';

@Component({
  selector: 'app-lead',
  templateUrl: './lead.component.html',
  styleUrls: ['./lead.component.scss'],
  providers: [
    ApiService,
    NotificacionesService,
    DataService
  ]
})
export class LeadComponent implements OnInit {

  public createLeadForm: FormGroup;
  public budget: any;
  public perceptions: any;
  public origins: any;
  public advertisingList: Array<any>;
  @Input() proposal: PropuestaConstructor;

  @Output() leadEvent = new EventEmitter<any>();
  @Output() onSkipClientEvent = new EventEmitter<any>();

  constructor(
    private formBuilder: FormBuilder,
    private apiConinsa: ApiConinsaService,
    private dataService: DataService,
    private ns: NotificacionesService,
  ) {
    this.budget = budget;
    this.perceptions = perceptions;
    this.origins = origins;
  }

  ngOnInit() {
    // Init info form group
    this.createLeadForm = this.formBuilder.group({
      firstName: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(40)]],
      secondName: ['', [Validators.minLength(3), Validators.maxLength(40)]],
      surname: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(40)]],
      secondSurname: ['', [Validators.minLength(3), Validators.maxLength(40)]],
      email: ['', [Validators.required, Validators.email]],
      mobile: ['', [Validators.required, Validators.minLength(10), Validators.pattern('^[0-9]*$')]],
      perception: [0, [Validators.required, Validators.min(1)]],
      budget: [0, [Validators.required, Validators.min(1)]],
      origin: [0, [Validators.required, Validators.min(1)]],
      dataTreatment: [false, [Validators.requiredTrue]],
      advertisingMedium: [null, [Validators.required]]
    });
    // Get advertising list
    this.getAdvertisingMedium();
  }

  get f() {
    return this.createLeadForm.controls;
  }

  /**
   * Method to call service to get pdf simulation
   */
  public async createLead() {
    // stop here if form is invalid
    if (this.createLeadForm.invalid) {
      return;
    }
    const project = JSON.parse(sessionStorage.getItem('selectProject'));
    this.ns.downdloadAlert();
    const formData = this.createLeadForm.value;
    const user = {
      id: null,
      primerNombre: formData.firstName,
      segundoNombre: formData.secondName,
      primerApellido: formData.surname,
      segundoApellido: formData.secondSurname,
      email: formData.email,
      telefono: formData.mobile
    };
    let ownerFounded = false;
    this.apiConinsa.getTokenZoho().subscribe(
      response => {
        this.dataService.getOwnersService()
          .subscribe(data => {
            data.map(item => {
              if ((project[0].CBPproyectoABR !== '0' &&  item.codigoABRProyecto !== '') &&
                (Number(project[0].CBPproyectoABR) === Number(item.codigoABRProyecto))
              ) {
                ownerFounded = true;
                const lead = {
                  token: response.access_token,
                  givenNames: `${formData.firstName} ${formData.secondName}`,
                  surnames: `${formData.surname} ${formData.secondSurname}`,
                  sucursal: project[0].sucursal,
                  idAsesorCRM: item.idAsesorCRM,
                  idProyectoCRM: item.idProyectoCRM,
                  email: formData.email,
                  mobile: formData.mobile,
                  budget: formData.budget,
                  perception: formData.perception,
                  origin: formData.origin,
                  advertisingMedium: formData.advertisingMedium
                };
                this.apiConinsa.createLead(lead).subscribe(
                  responseLead => {
                    if (responseLead.data[0].code === 'SUCCESS') {
                      user.id = responseLead.data[0].details.id;
                      sessionStorage.setItem('user', JSON.stringify(user));
                      this.ns.closeAlert();
                      this.leadEvent.emit();
                    } else {
                      this.ns.errorAlert(`Error al guardar el lead en zoho: ${JSON.stringify(responseLead.data[0])}`);
                    }
                  }, () => {
                    this.ns.closeAlert();
                    this.ns.errorAlert('Error al guardar el lead en zoho');
                  }
                );
              }
            });
            if (!ownerFounded) {
              this.ns.closeAlert();
              this.ns.errorAlert('No se encontró un asesor para este proyecto');
            }
          });
      },
      () => {
        this.ns.closeAlert();
        this.ns.errorAlert('Error al guardar el lead en zoho');
      }
    );
  }

  /**
   * Get advertising mediums list
   * @private
   */
  private getAdvertisingMedium() {
    this.dataService.getAdvertisingMediumService()
      .subscribe(data => {
        this.advertisingList = data;
      });
  }

  public onSkipClient() {
    this.onSkipClientEvent.emit();
  }

}
