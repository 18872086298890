import {UnidadOpcionada} from './unidadOpcionada';

export class Opcion {
  constructor(
    public empresa: number,
    public idProyecto: number,
    public lstUnidad: Array<UnidadOpcionada>,
    public fechaInicialOpcion: string,
    public fechaFinalOpcion: string,
    public tipoDocumento: string,
    public identificacion: number,
    public primerNombre: string,
    public segundoNombre: string,
    public primerApellido: string,
    public segundoApellido: string
  ) {
  }
}
