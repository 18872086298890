import { Component } from '@angular/core';
import { ApiService } from '../shared/services/api.service';
import { NotificacionesService } from '../shared/services/notificaciones.service';
import { Router } from '@angular/router';

@Component({
    selector: 'loggin',
    templateUrl: '../views/loginAplicacion.component.html',
    providers: [ApiService, NotificacionesService]
})

export class LoginAplicacionComponent{

    public usuario: any = {
        email: <string> '',
        password: <string> ''
    };
    public errores: any = {
        errorEmail: <string> '',
        errorPassword: <string> ''
    };
    public flag: boolean;
    public token: string;

    constructor(private _bs: ApiService, private _fs: NotificacionesService, private _route: Router){
        this.flag = false;
    }
    public validarUsuario(): any{
        if(!this.validacionesFormulario()){
            this._fs.errorAlert('El formulario presenta errores, por favor validar');
            return false;
        }
        this._fs.downdloadAlert();
        this._bs.validarUsuarioService(this.usuario).subscribe(
            response => {
                this._fs.closeAlert();
                this.token = response.token;
                console.log(this.token);
                localStorage.setItem('ta', this.token);
                this._route.navigate(["/admin/parametros"]);
            },
            error => {
                this._fs.closeAlert();
                this._fs.errorAlert();
            }
        );
    }

    private validacionesFormulario():boolean {
        if(this.usuario.email == '' ){
            this.errores.errorEmail = 'El campo usuario no debe estar vacio';
            this.flag = true;
        }
        if(this.usuario.password == '' ){
            this.errores.errorPassword = 'El campo contraseña no debe estar vacio';
            this.flag = true;
        }
        if(this.flag){
            return false;
        }
        return true;
    }

}
