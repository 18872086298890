import {Injectable} from '@angular/core';

declare var swal: any;

@Injectable()

export class NotificacionesService {
  public downdloadAlert(message: string = 'Cargando información') {
    swal({
      title: 'Un momento por favor',
      text: message,
      onOpen: () => {
        swal.showLoading();
      },
      allowOutsideClick: false,
      customClass: 'alert',
    });
  }

  public errorAlert(message: string = 'Error cargando la información') {
    swal({
      title: 'Se ha presentado un error',
      text: message,
      customClass: 'alert is-danger',
      imageUrl: '/ui-assets/images/circle-xmark.svg',
      buttonsStyling: false,
      confirmButtonClass: 'btn is-primary',
    });
  }

  public successAlert(message: string = 'Proceso realizado exitosamente') {
    swal({
      title: 'Perfecto!',
      text: message,
      type: 'success',
      customClass: 'alert',
    });
  }

  public warningAlert(message: string = ''): any {
    return swal({
      title: 'Atención!',
      text: message,
      customClass: 'alert is-warned',
      imageUrl: '/ui-assets/images/circle-info.svg',
      buttonsStyling: false,
      confirmButtonClass: 'btn is-primary',
    });
  }

  public addNumberQuotes(message: string = ''): any {
    return swal({
      title: message,
      input: 'text',
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      showLoaderOnConfirm: true,
      confirmButtonClass: 'btn is-primary',
      cancelButtonClass: 'btn is-secondary',
      inputValidator: (value) => {
        if (!value || isNaN(value)) {
          return 'Debes escribir un valor numérico';
        }
        if (Number(value) > 60) {
          return 'Máximo 60 cuotas';
        }
      },
      allowOutsideClick: () => !swal.isLoading(),
      customClass: 'alert',
      buttonsStyling: false,
    });
  }

  public addValueQuotes(message: string = ''): any {
    return swal({
      title: message,
      input: 'text',
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      showLoaderOnConfirm: true,
      confirmButtonColor: '#3085d6',
      inputValidator: (value) => {
        if (!value || isNaN(value)) {
          return 'Debes escribir un valor numérico';
        }
      },
      allowOutsideClick: () => !swal.isLoading(),
      customClass: 'alert',
    });
  }

  public deleteQuote(message: string = ''): any {
    return swal({
      title: message,
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: 'Eliminar',
      cancelButtonText: 'Eliminar todas las cuotas iguales',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#ea5455',
      allowOutsideClick: () => !swal.isLoading(),
      customClass: 'alert',
    });
  }

  public confirmationAlert(title: string = '¿Estás seguro?', text: string = '¡No podrás revertir esto!', confirmButtonText: string = 'Aceptar', cancelButtonText: string = 'Cancelar'){
    return swal({
      title: title,
      text: text,
      imageUrl: '/ui-assets/images/circle-info.svg',
      showCancelButton: true,
      confirmButtonText: confirmButtonText,
      cancelButtonText: cancelButtonText,
      confirmButtonClass: 'btn is-primary',
      cancelButtonClass: 'btn is-secondary',
      customClass: 'alert is-warned',
      buttonsStyling: false,
    })
  }

  public closeAlert() {
    swal.close();
  }
}
