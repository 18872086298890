import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { User } from '../shared/models/user';
import { ApiConinsaService } from '../shared/services/apiConinsa.service';

@Component({
  templateUrl: '../views/landing.component.html',
  providers: [ApiConinsaService]
})

export class LandingComponent implements OnInit {
  public user: User;
  public username: string;
  public password: string;
  constructor(private _aRoute: ActivatedRoute, private _route: Router, private _cs: ApiConinsaService) {
    this.user = new User(0, '', '', '', '', 0, 0, '', '', '');
  }

  public ngOnInit() {
    this.login();
  }

  public login(): any {
    this._aRoute.queryParams.subscribe(
      params => {
        var nombres = (params['nombres']) ? params['nombres'].split(' ') : [];
        var apellidos = (params['apellidos']) ? params['apellidos'].split(' ') : [];
        this.user.id = params['id'];
        this.user.primerNombre = (nombres[0] != null) ? nombres[0] : '';
        this.user.segundoNombre = (nombres[1] != null) ? nombres[1] : '';
        this.user.primerApellido = (apellidos[0] != null) ? apellidos[0] : '';
        this.user.segundoApellido = (apellidos[1] != null) ? apellidos[1] : '';
        this.user.cedula = params['documento'];
        this.user.telefono = params['movil'];
        this.user.email = params['email'];
        this.user.type_document = params['tipoIdentificacion'];
        this.user.module = params['modulo'] ? params['modulo'] : '';
      }
    );

    this._cs.loginService().subscribe(
      response => {
        sessionStorage.setItem('t', response.token);
        sessionStorage.setItem('user', JSON.stringify(this.user));
        this._route.navigate(["app/filters"]);
      },
      error => {
        console.log("No es posible realizar la autenticación para los restfull");
      }
    );
  }
}
