import {Pipe, PipeTransform} from '@angular/core';
import {Units} from '../models/units';

@Pipe({
  name: 'filtroAdicionales',
  pure: false
})

export class FiltroAdicionales implements PipeTransform {
  transform(items: Units[], filtro): any {
    if (!items || !filtro) {
      return items;
    }
    return items.filter(item => item.descTipoUnidad.toUpperCase().indexOf(filtro.toUpperCase()) !== -1);
  }
}
