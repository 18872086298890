export const budget = [
  {id: 'si', name: 'Si'},
  {id: 'no', name: 'No'}
];

export const perceptions = [
  {id: 'Alto (Reúne los requisitos / le gusta el inmueble)', name: 'Alto (Reúne los requisitos / le gusta el inmueble)'},
  {id: 'Medio (Reúne los requisitos / tiene otras opciones)', name: 'Medio (Reúne los requisitos / tiene otras opciones)'},
  {id: 'Bajo (No cumple los requisitos / le gusta el inmueble)', name: 'Bajo (No cumple los requisitos / le gusta el inmueble)'},
];

export const origins = [
  {id: 'base de datos', name: 'Base de Datos'},
  {id: 'chatbot', name: 'Chatbot'},
  {id: 'cliente actual', name: 'Cliente actual'},
  {id: 'digital', name: 'Digital'},
  {id: 'email', name: 'Email'},
  {id: 'evento', name: 'Evento'},
  {id: 'llamada al Cliente', name: 'Llamada al Cliente'},
  {id: 'llamada del cliente', name: 'Llamada del Cliente'},
  {id: 'portales web', name: 'Portales web'},
  {id: 'visita sala', name: 'Visita Sala'},
  {id: 'visita sede', name: 'Visita Sede'},
  {id: 'visita stand', name: 'Visita Stand'},
  {id: 'visita al cliente', name: 'Visita al Cliente'},
  {id: 'whatsapp', name: 'Whatsapp'}
];

export const currencies = [
  {id: 'cop', name: 'COP'},
  {id: 'usd', name: 'USD'},
  {id: 'eur', name: 'EUR'},
];
