import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";

@Component({
  selector: 'app-main-header-admin',
  templateUrl: './mainHeaderAdmin.component.html'
})
export class MainHeaderAdminComponent implements OnInit {

  constructor(private _ro: Router) { }

  ngOnInit() {
  }

  public cerrarSesion(): any{
		localStorage.removeItem('ta');
		this._ro.navigate(['/login']);
	}
}
