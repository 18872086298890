import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {ThemesModule} from '../themes/themes.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import {LimpiarStringPipe} from './pipes/limpiarString.pipe';
import { ConvertCurrencyPipe } from './pipes/convertCurrency.pipe';
import {FiltroAdicionales} from './pipes/filtroAdicionales.pipe';
import {OnlyNumberDirective} from './directives/onlyNumber.directive';
import {NgxMyDatePickerModule} from 'ngx-mydatepicker';
import {CurrencyMaskModule} from 'ng2-currency-mask';
import {OpcionarComponent} from './components/opcionar/opcionar.component';
import {AdicionalesComponent} from './components/adicionales/adicionales.component';
import {MinDirective} from './directives/min.directive';
import {LeadComponent} from './components/lead/lead.component';
import { CurrencyComponent } from './components/currency/currency.component';
import { CreditYearComponent } from './components/credit-year/credit-year.component';


@NgModule({
  imports: [
    CommonModule,
    ThemesModule,
    FormsModule,
    NgxMyDatePickerModule.forRoot(),
    CurrencyMaskModule,
    NgSelectModule,
    ReactiveFormsModule,
  ],
  declarations: [
    LimpiarStringPipe,
    ConvertCurrencyPipe,
    OnlyNumberDirective,
    MinDirective,
    OpcionarComponent,
    AdicionalesComponent,
    FiltroAdicionales,
    LeadComponent,
    CurrencyComponent,
    CreditYearComponent
  ],
  exports: [
    CommonModule,
    ThemesModule,
    FormsModule,
    LimpiarStringPipe,
    ConvertCurrencyPipe,
    OnlyNumberDirective,
    NgxMyDatePickerModule,
    CurrencyMaskModule,
    OpcionarComponent,
    AdicionalesComponent,
    NgSelectModule,
    LeadComponent,
    CurrencyComponent,
    CreditYearComponent
  ]
})
export class SharedModule {
}
