import {Injectable, ɵConsole} from '@angular/core';
import {Http, Headers, RequestOptions} from '@angular/http';
import {map} from 'rxjs/operators';
import {Filtros} from '../models/filtros';
import {Opcion} from '../models/opcion';
import {User} from '../models/user';
import {environment} from 'src/environments/environment';

@Injectable()

export class ApiConinsaService {
  private url: string;
  private urlZoho: string;
  private user: User;

  constructor(private _http: Http) {
    this.user = JSON.parse(sessionStorage.getItem('parametros'));
    this.url = environment.apiCotizador + '/api/sinco/';
    this.urlZoho = environment.apiCotizador + '/api/zoho/';
  }

  // Config conection services
  private conectionConfig(origin: number = 1): RequestOptions {
    let headers = new Headers();
    if (origin == 1) {
      headers.append('tokenKeyValue', sessionStorage.getItem('t'));
    }
    const options = new RequestOptions({headers: headers});
    return options;
  }

  // Get All Cities
  public getCitiesService() {
    var petitionOptions: RequestOptions = this.conectionConfig();
    return this._http.get(this.url + 'Ciudades', petitionOptions).pipe(map(res => res.json()));
  }

  // Get All Business
  public getBusinessService() {
    var petitionOptions: RequestOptions = this.conectionConfig();
    return this._http.get(this.url + 'Empresas', petitionOptions).pipe(map(res => res.json()));
  }

  // Get Projects
  public getProjectsService(filtros: Filtros) {
    var ciudad;
    var empresa;
    ciudad = (filtros.ciudad == 0) ? '' : filtros.ciudad;
    empresa = (filtros.empresa == 0) ? '' : filtros.empresa;
    var petitionOptions: RequestOptions = this.conectionConfig();
    var filtrosData = JSON.stringify({
      'idCiudad': ciudad,
      'idEmpresa': empresa
    });
    return this._http.post(this.url + 'Proyectos', filtrosData, petitionOptions).pipe(map(res => res.json()));
  }

  // Get Properties
  public getUnitsService(filtros: Filtros) {
    var proyecto;
    var empresa;
    proyecto = (filtros.proyecto == 0) ? '' : filtros.proyecto;
    empresa = (filtros.empresa == 0) ? '' : filtros.empresa;
    var petitionOptions: RequestOptions = this.conectionConfig();
    var filtrosData = JSON.stringify({
      'idEmpresa': empresa,
      'idProyecto': proyecto
    });
    return this._http.post(this.url + 'Unidades', filtrosData, petitionOptions).pipe(map(res => res.json()));
  }

  public getUnitsOthersService(filtros: Filtros) {
    var proyecto;
    var empresa;
    proyecto = (filtros.proyecto == 0) ? '' : filtros.proyecto;
    empresa = (filtros.empresa == 0) ? '' : filtros.empresa;
    var petitionOptions: RequestOptions = this.conectionConfig();
    var filtrosData = JSON.stringify({
      'idEmpresa': empresa,
      'idProyecto': proyecto
    });
    return this._http.post(this.url + 'UnidadesOtros', filtrosData, petitionOptions).pipe(map(res => res.json()));
  }

  // Login post
  public loginService(): any {
    var petitionOptions: RequestOptions = this.conectionConfig(2);
    return this._http.post(this.url + 'Login', petitionOptions).pipe(map(res => res.json()));
  }

  public getTokenZoho(): any {
    const petitionOptions = this.conectionConfig(2);
    return this._http.post(this.urlZoho + 'Login', {}, petitionOptions).pipe(map(res => res.json()));
  }

  // Servicio para opcionar unidades
  public opcionarService(opcion: Opcion): any {
    const options = this.conectionConfig();
    const jsonData = JSON.stringify(opcion);
    return this._http.post(this.url + 'Opcionados', jsonData, options).pipe(map(res => res.json()));
  }

  // Save pdf zoho version 2
  public saveQuotationsZohoService(parametros: any, accessToken: string): any {
    // Cabeceras de la petición
    const headers = new Headers();
    headers.append('Authorization', 'Zoho-oauthtoken ' + accessToken);
    const options = new RequestOptions({headers: headers});

    // Elegir modulo en la api de zoho
    let module = 'Leads/';
    this.user = JSON.parse(sessionStorage.getItem('user'));
    if (this.user.module == 'contactosdenegocio') {
      module = 'Contacts/';
    }
    var url = this.urlZoho + module + parametros.id_zoho;
    var body = {};
    if (parametros.tipoPersona) {
      body['Tipo_de_persona'] = 'Persona Jurídica';
      body['Company'] = parametros.company;
      body['NIT'] = parametros.tipoIdentificacion;
      body['ultima_cotizacion'] = parametros.ultimaCotizacion;
      body['historial_cotizaciones'] = parametros.historialCotizaciones;
      body['_rea_de_inter_s_1'] = parametros.areaConstruida;
      body['presenta_propuesta'] = parametros.presentaPropuesta;
    } else {
      body['Tipo_de_persona'] = 'Persona Natural';
      body['First_Name'] = parametros.firstName;
      body['Last_Name'] = parametros.lastName;
      body['Tipo_de_Documento'] = parametros.tipoIdentificacion;
      body['Documentoid'] = parametros.documentoId;
      body['Email'] = parametros.email;
      body['Mobile'] = parametros.mobile;
      body['ultima_cotizacion'] = parametros.ultimaCotizacion;
      body['historial_cotizaciones'] = parametros.historialCotizaciones;
      body['_rea_de_inter_s_1'] = parametros.areaConstruida;
      body['presenta_propuesta'] = parametros.presentaPropuesta;
    }

    if (parametros.opcionado) {
      body['Inmueble_Opcionado'] = parametros.inmuebleOpcionado;
      body['Fecha_vigencia_opci_n'] = parametros.fechaVigenciaOpcion;
      body['Opciona_inmueble'] = parametros.opcionado;
    }
    var data = JSON.stringify(body);
    return this._http.put(url, data, options).pipe(map(res => res.json()));
  }

  /**
   * Create lead service
   * @param lead
   */
  public createLead(lead: any): any {
    const headers = new Headers();
    const options = new RequestOptions({headers});
    return this._http.post(this.urlZoho + 'Leads', lead, options).pipe(map(res => res.json()));
  }

  /**
   * get lead by phone number
   * @param lead
   */
  public getLead(lead: any): any {
    const headers = new Headers();
    const options = new RequestOptions({headers});
    return this._http.post( `${this.urlZoho}Leads/phone/${lead.mobile}`, lead, options).pipe(map(res => res.json()));
  }

  /**
   * GET info project from coninsa.co
   * @param idProject - Id ABR project
   */
  public getInfoProjectService(idProject): any {
    return this._http.get(environment.urlApiConinsa + 'proyectoxid/' + idProject).pipe(map(res => res.json()));
  }

  /**
   * GET info property from coninsa.co
   * @param propertyId - Id ABR property
   */
  public getInfoPropertyService(propertyId): any {
    return this._http.get(environment.urlApiConinsa + 'inmueblesxid/' + propertyId).pipe(map(res => res.json()));
  }

}
