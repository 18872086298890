import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'gp-admin-layout',
  templateUrl: 'adminLayout.component.html',
  styles: []
})
export class AdminLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
}
