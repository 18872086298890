import { formatNumber } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyConvertService } from '../services/currencyConvert.service';

@Pipe({name: 'convertCurrency'})
export class ConvertCurrencyPipe implements PipeTransform {

  constructor(private _currencyConvertService: CurrencyConvertService) { }

  transform(value: number = 0, currency: string = 'cop', exchange: number = 1): string {
    return this._currencyConvertService.formatCurrency(value, currency, exchange);
  }
}
