import { Component } from '@angular/core';
import { ApiService } from '../shared/services/api.service';
import { NotificacionesService } from '../shared/services/notificaciones.service';
import { Parametros } from '../shared/models/parametros';
import { Router } from "@angular/router";

@Component({
    selector: 'parametros',
    templateUrl: '../views/parametros.component.html',
    providers: [ApiService, NotificacionesService]
})

export class ParametrosComponent {

    public flag: boolean;
    public errores: any = {};
    public parametros: Parametros[] = [];

    constructor(private _bs: ApiService, private _fs: NotificacionesService, private _ro: Router) {
        this.flag = false;
        this.cargarParametros();
    }
    public guardarParametros(): any {
        if (!this.validacionesFormulario()) {
            this._fs.errorAlert('El formulario no puede contener valores vacios ');
            return false;
        }
        this._fs.downdloadAlert();
        this._bs.guardarParametrosService(this.parametros).subscribe( 
            response => {
                this._fs.closeAlert();
                this._fs.successAlert();
            },
            error => {
                this._fs.closeAlert();
                this._fs.errorAlert();
            }
        );
    }

    private validacionesFormulario(): boolean {
        this.flag = false;
        this.parametros.forEach(
            x => {
                if(x.value == ''){
                    this.flag = true;
                }
            }
        )
        if (this.flag) {
            return false;
        }
        return true;
    }

    public cargarParametros(): any {
        this._fs.downdloadAlert();
        this._bs.getParameters().subscribe(
            response => {
                this.parametros = response;
                this._fs.closeAlert();
            },
            error => {
                console.log('No se pueden cargar los parametros, por favor comunicarse con el administrador del sistema');
                this._fs.closeAlert();
            }
        );
    }
}
