import { ModuleWithProviders } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AppComponent } from "./app.component";
import { CalculatorComponent } from "./components/calculator.component";
import { FilterComponent } from "./components/filter.component";
import { ApplicationComponent } from "./components/application.component";
import { AdminLayoutComponent } from "./themes/admin_layout/adminLayout.component";
import { LandingComponent } from "./components/landing.component";
import { SessionGuard } from "./shared/guards/sessionGuard";
import { ListadoCotizacionesComponent } from "./components/listadoCotizaciones.component";
import { LayoutRamonComponent } from "./components/layoutRamon.component";
import { LoginAplicacionComponent } from "./components/loginAplicacion.component";
import { ParametrosComponent } from "./components/parametros.component";
import { AdminGuard } from "./shared/guards/adminGuard";
import { LoginGuard } from "./shared/guards/loginGuard";

export const appRoutes: Routes = [
  {
    path: "",
    component: LandingComponent,
  },
  {
    path: "consulta",
    component: LayoutRamonComponent,
    children: [
      {
        path: "listado-cotizaciones",
        component: ListadoCotizacionesComponent,
      },
    ],
  },
  {
    path: "app",
    component: ApplicationComponent,
    children: [
      {
        path: "filters",
        component: FilterComponent,
        canActivate: [SessionGuard],
      },
      {
        path: "calculator",
        component: CalculatorComponent,
        canActivate: [SessionGuard],
      },
      {
        path: "calculator/client",
        loadChildren: "./cliente/cliente.module#ClienteModule",
        canActivate: [SessionGuard],
      },
    ],
    canActivate: [SessionGuard],
  },
  {
    path: "login",
    component: LoginAplicacionComponent,
    canActivate: [LoginGuard],
  },
  {
    path: "admin",
    component: AdminLayoutComponent,
    canActivate: [AdminGuard],
    children: [
      {
        path: "conceptos",
        loadChildren: "./conceptos/conceptos.module#ConceptosModule",
      },
      {
        path: "parametros",
        component: ParametrosComponent,
      },
      {
        path: "credito",
        loadChildren: "./credit/credit.module#CreditModule",
      },
    ],
  },
  {
    path: "**",
    component: LandingComponent,
  },
];

export const appRoutingProviders: any[] = [];
export const routing: ModuleWithProviders = RouterModule.forRoot(appRoutes);
