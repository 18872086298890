import { Injectable } from '@angular/core';

import { Cotizacion } from '../models/cotizacion';
import { CurrencyConvertService } from './currencyConvert.service';

@Injectable({ providedIn: 'root' })
export class QuotationCurrencyConvert {

  public exchange: number = 1;

  constructor(private _ccs: CurrencyConvertService) { }

  public convertQuotation(cotizacion: Cotizacion, creditYear: number, currency: string = 'cop', exchange: number = 1,  note: string = ''): any {
    this.exchange = exchange;

    return {
      ...cotizacion,
      propuestaConstructor: {
        ...cotizacion.propuestaConstructor,
        ahorroProgramado: this.convertValue(cotizacion.propuestaConstructor.ahorroProgramado),
        cesantias: this.convertValue(cotizacion.propuestaConstructor.cesantias),
        cuotaAdicionalMensual: this.convertValue(cotizacion.propuestaConstructor.cuotaAdicionalMensual),
        cuotaInicialTotal: this.convertValue(cotizacion.propuestaConstructor.cuotaInicialTotal),
        descuentoComercial: this.convertValue(cotizacion.propuestaConstructor.descuentoComercial),
        gastosEscrituracion: this.convertValue(cotizacion.propuestaConstructor.gastosEscrituracion),
        saldoCuotaInicial:this.convertValue(cotizacion.propuestaConstructor.saldoCuotaInicial),
        subsidio: this.convertValue(cotizacion.propuestaConstructor.subsidio),
        subsidioCredito: this.convertValue(cotizacion.propuestaConstructor.subsidioCredito),
        subsidioInicial: this.convertValue(cotizacion.propuestaConstructor.subsidioInicial),
        totalCalculadoMenosDescuento: this.convertValue(cotizacion.propuestaConstructor.totalCalculadoMenosDescuento),
        valorApartar: this.convertValue(cotizacion.propuestaConstructor.valorApartar),
        valorCredito: this.convertValue(cotizacion.propuestaConstructor.valorCredito),
        valorInmueble: this.convertValue(cotizacion.propuestaConstructor.valorInmueble),
        creditoHipotecario: cotizacion.propuestaConstructor.creditoHipotecario.map((item) => {
          return {
            ...item,
            ingresosReq: this.convertValue(item.ingresosReq),
            ingresosReqUVR: this.convertValue(item.ingresosReqUVR),
            valorCredito: this.convertValue(item.valorCredito),
            valorCuota: this.convertValue(item.valorCuota),
            valorCuotaUVR: this.convertValue(item.valorCuotaUVR),
          }
        }),
        cuotas: cotizacion.propuestaConstructor.cuotas.map((item) => {
          return {
            ...item,
            valorCuota: this.convertValue(item.valorCuota)
          }
        }),
        formaPagoGastsEscr: cotizacion.propuestaConstructor.formaPagoGastsEscr.map((item) => {
          return {
            ...item,
            gastosEscrituracion: this.convertValue(item.gastosEscrituracion)
          }
        }),
        unidades: cotizacion.propuestaConstructor.unidades.map((item) => {
          return {
            ...item,
            valor: this.convertValue(item.valor)
          }
        })
      },
      propuestaCliente: cotizacion.propuestaCliente ? {
        ...cotizacion.propuestaCliente,
        valorApartar: this.convertValue(cotizacion.propuestaCliente.valorApartar),
        valorCredito: this.convertValue(cotizacion.propuestaCliente.valorCredito),
        valorCuotaInicial: this.convertValue(cotizacion.propuestaCliente.valorCuotaInicial),
        cuotas: cotizacion.propuestaCliente.cuotas.map((item) => {
          return {
            ...item,
            valorCuota: this.convertValue(item.valorCuota)
          }
        }),
      } : null,
      valorAplicacionDiferencia: this.convertValue(cotizacion.valorAplicacionDiferencia),
      valorDescuentoCliente: this.convertValue(cotizacion.valorDescuentoCliente),
      valorInteresesCliente: this.convertValue(cotizacion.valorInteresesCliente),
      valorTotalPagar: this.convertValue(cotizacion.valorTotalPagar),
      currency,
      currencySymbol: this._ccs.currencySymbol(currency),
      note,
      creditYear
    }
  }

  convertValue(value: number) {
    return value ? this._ccs.convertCurrency(value, this.exchange) : value
  }

}
