import {Component, Output, EventEmitter, Input, ViewChild, OnChanges, SimpleChanges} from '@angular/core';
import {constantes} from '../../constants/app.constant';
import {User} from 'src/app/shared/models/user';
import {CotizadorService} from 'src/app/shared/services/cotizador.service';
import {Units} from 'src/app/shared/models/units';
import {PropuestaConstructor} from 'src/app/shared/models/propuestaConstructor';
import {Projects} from 'src/app/shared/models/projects';
import {Filtros} from 'src/app/shared/models/filtros';

declare var $: any;

@Component({
  selector: 'app-opcionar-propuesta',
  templateUrl: './opcionar.component.html'
})

export class OpcionarComponent implements OnChanges {
  @ViewChild('datosUsuarioForm') datosUsuarioForm: any;

  /**
   * Unidades seleccionadas
   */
  @Input() selectedUnits: Units[] = [];

  /**
   * Propuesta
   */
  @Input() cotizacionPdf: PropuestaConstructor;

  /**
   * Proyecto seleccionado
   */
  @Input() selectProject: Projects;

  /**
   * Filtros seleccionados
   */
  @Input() filtros: Filtros;

  /**
   * f
   */
  @Output() valoresOpcionarEvent = new EventEmitter<any>();

  /**
   * User
   */
  @Input() user: User;

  /**
   * Contantes
   */
  public contantes: any;

  /**
   * Tipo persona juridica
   */
  public tipoPersonaJuridica: number;

  /**
   * Nombre completo
   */
  public nombreCompleto: string;

  constructor(public _cs: CotizadorService) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.user) {
      this.contantes = constantes;
      this.inicializarPersona();
    }
  }

  /**
   * Inicializa persona
   */
  private inicializarPersona(): void {
    this.nombreCompleto = this.user.primerNombre + ' ' + this.user.segundoNombre + ' ' + this.user.primerApellido + ' ' + this.user.segundoApellido;
    this.tipoPersonaJuridica = 0;
    this.user.type_document = 'CC';
    if (this.tipoPersonaJuridica) {
      this.user.type_document = 'NIT';
    }
  }

  public guardarUsuario(): any {
    if (!this.datosUsuarioForm.valid) {
      return;
    }
    if (this.tipoPersonaJuridica) {
      this.user.type_document = 'NIT';
    }
    sessionStorage.setItem('user', JSON.stringify(this.user));
    this.nombreCompleto = this.user.primerNombre + ' ' + this.user.segundoNombre + ' ' + this.user.primerApellido + ' ' + this.user.segundoApellido;

    this.cotizacionPdf.name = this.nombreCompleto;
    this.cotizacionPdf.type_document = this.user.type_document;
    this.cotizacionPdf.document = this.user.cedula;
    this.cotizacionPdf.email = this.user.email;
    this.cotizacionPdf.phone = this.user.telefono;
    this.sendValues();
  }

  /**
   * Dispara el eventEmitter
   */
  public sendValues(): void {
    this.valoresOpcionarEvent.emit(
      {
        cotizacionPdf: this.cotizacionPdf,
        tipoPersonaJuridica: this.tipoPersonaJuridica,
        user: this.user,
        nombreCompleto: this.nombreCompleto
      }
    );
  }

}
