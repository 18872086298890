import { Component, OnInit } from "@angular/core";
import { ApiService } from "../shared/services/api.service";
import { ActivatedRoute } from "@angular/router";
import { NotificacionesService } from "../shared/services/notificaciones.service";

@Component({
  selector: 'listado-cotizaciones',
  templateUrl: '../views/listadoCotizaciones.component.html',
  providers: [ApiService, NotificacionesService]
})

export class ListadoCotizacionesComponent implements OnInit {
  public listadoCotizaciones: Array<Object>;
  public idZoho: number;
  constructor(
    private beps: ApiService,
    private _aRoute: ActivatedRoute,
    private _fs: NotificacionesService
  ) {}
  public ngOnInit() {

    this._aRoute.queryParams.subscribe(
      params => {
        this.idZoho = params['id_zoho'];
      }
    );
    this.beps.obtenerCotizaciones(this.idZoho).subscribe(
      response => {
        this.listadoCotizaciones = response.data
      },
      error => {
        this._fs.errorAlert('Error generando el listado de cotizaciones, por favor comunicarse con el administrador del sistema');
      }
    );
  }
}
