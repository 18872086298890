export class Filtros {
  constructor(
    public empresa: number,
    public ciudad: number,
    public megaproyecto: number,
    public proyecto: number,
    public inmueble: number
  ) {
  }
}
