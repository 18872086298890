import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'filtroAptos'
})

export class FiltroAptosPipe implements PipeTransform {
  // passing another argument

  transform(items: any[]): any {
    return items.filter(item => item.idTipoUnidad === '63');
  }
}
