import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'limpiarString'
})

export class LimpiarStringPipe implements PipeTransform {
  transform(stringLimpiar: string, caracterReemplazar: string, caracterParaReemplazar: string = ''): any {
    if (stringLimpiar && caracterReemplazar) {
      return stringLimpiar.replace(new RegExp(caracterReemplazar, 'g'), caracterParaReemplazar);
    }
    return stringLimpiar;
  }
}
