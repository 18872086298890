import {Component, EventEmitter, Output, Input, OnInit} from '@angular/core';
import {ApiConinsaService} from 'src/app/shared/services/apiConinsa.service';
import {Units} from 'src/app/shared/models/units';
import {NotificacionesService} from 'src/app/shared/services/notificaciones.service';

@Component({
    selector: 'app-adicionales',
    templateUrl: 'adicionales.component.html'
})

export class AdicionalesComponent implements OnInit {

    /**
     * Filtros
     */
    public filtros;

    /**
     * Unidades
     */
    public units: Units;

    /**
     * Unidades seleccionadas
     */
    @Input() selectedUnits: Units[] = [];

    /**
     * Filtro
     */
    public filtro: string;

    /**
     * IDs unidades
     */
    public idsUnits: number[] = [];

    /**
     * EventEmitter Adicionales
     */
    @Output() adicionalesEvent = new EventEmitter<any>();

    constructor(private _acs: ApiConinsaService, private _ns: NotificacionesService) {
        this.filtros = JSON.parse(sessionStorage.getItem('filtros'));
    }

    public ngOnInit() {
        this.cargarAdicionales();
        this.inicializarUnidades();
    }

    public inicializarUnidades() {
        this.selectedUnits.forEach(selectedUnit => {
            if (this.filtros.inmueble != selectedUnit.idUnidad) {
                this.idsUnits.push(selectedUnit.idUnidad);
            }
        });
    }

    /**
     * Agregar adicional
     * @param element
     * @param event
     */
    public agregarAdicionales(element, event) {
        if (event.target.checked) {
            this.idsUnits.push(element);
        } else {
            let eliminar = this.idsUnits.indexOf(element);
            if (eliminar !== -1) {
                this.idsUnits.splice(eliminar, 1);
            }
        }
    }

    /**
     * Carga las unidades adicionales
     */
    public cargarAdicionales(): any {
        this._acs.getUnitsOthersService(this.filtros).subscribe(
            response => {
                this.units = response;
            },
            error => {
                this._ns.errorAlert('No se pueden cargar las unidades, por favor comunicarse con el administrador del sistema');
            }
        );
    }

    /**
     * Validacion de las unidades adicionadas
     */
    public validarUnidad(idUnidad): boolean {
        let selected = false;
        this.selectedUnits.forEach(unidad => {
            if (unidad.idUnidad == idUnidad) {
                selected = true;
            }
        });
        return selected;
    }

    /**
     * Dispara el eventEmitter
     */
    public sendValues(): void {
        this.adicionalesEvent.emit(
            {
                idsUnits: this.idsUnits,
                units: this.units
            }
        );
    }
}
