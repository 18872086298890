import {Injectable} from '@angular/core';
import { formatNumber } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class CurrencyConvertService {

  constructor() { }

  formatCurrency(value: number = 0, currency: string = 'cop', exchange: number = 1):string {
    const symbol = this.currencySymbol(currency);
    const result = this.convertCurrency(value, exchange);
    const formatResult = formatNumber(result, 'en-US', '1.0-0');
    return `${symbol} ${formatResult}`;
  }

  convertCurrency(value: number = 0, exchange: number = 1):number {
    return value / exchange;
  }

  currencySymbol(currency: string = 'cop'):number {
    const symbolMap = {
      cop: '$',
      usd: '$ USD',
      eur: '€',
    }
    return symbolMap[currency];
  }

}
