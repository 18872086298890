export class Units {
  constructor(
    public idUnidad: number,
    public idTipoUnidad: number,
    public descTipoUnidad: string,
    public nomenclatura: string,
    public areaConstruida: number,
    public areaTotal: number,
    public areaPrivada: number,
    public valor: number,
    public estado: string,
    public fechaFinalOpcion: string,
    public venta: number,
    public tipo: number,
    public fechaOpcion: string,
    public idInmuebleABR: string,
    public note: string,
  ) {
  }
}
