import {Injectable} from '@angular/core';
import {Router, CanActivate} from '@angular/router';

@Injectable()

export class AdminGuard {

  constructor(private _ro: Router) {
  }

  canActivate() {
    if (localStorage.getItem('ta')) {
      return true;
    }
    this._ro.navigate(['/login']);
    return false;
  }
}
