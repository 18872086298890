export class Projects {
  constructor(
    public idProyecto: number,
    public idBaseDatos: number,
    public ciudad: string,
    public nombre: string,
    public preventa: number,
    public interesFin: number,
    public interesCte: number,
    public fechaEntrega: string,
    public maxCuotas: string,
    public minCuotas: string,
    public VPN: number,
    public financia: number,
    public cuotaInicial: number,
    public separacion: number,
    public valorSeparacion: number,
    public diasSepara: number,
    public proyectoVIS: string,
    public CBPproyectoABR: string,
    public sucursal: string
  ) {
  }
}
