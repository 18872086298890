import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';
import { appRoutingProviders, routing, appRoutes } from './app.routing';
import { AppComponent } from './app.component';
import { ApplicationComponent } from './components/application.component';
import { CalculatorComponent } from './components/calculator.component';
import { FilterComponent } from './components/filter.component';
import { LandingComponent } from './components/landing.component';
import { FiltroAptosPipe } from './shared/pipes/filtroAptos.pipe';
import { FiltroOtrasUnidades } from './shared/pipes/filtroOtrasUnidades.pipe';
import { SessionGuard } from './shared/guards/sessionGuard';
import { RouterModule } from '@angular/router';
import { ListadoCotizacionesComponent } from './components/listadoCotizaciones.component';
import { LayoutRamonComponent } from './components/layoutRamon.component';
import { LoginAplicacionComponent } from './components/loginAplicacion.component';
import { ParametrosComponent } from './components/parametros.component';
import { AdminGuard } from './shared/guards/adminGuard';
import { LoginGuard } from './shared/guards/loginGuard';
import { SharedModule } from './shared/shared.module';

@NgModule({
  declarations: [
    AppComponent,
    CalculatorComponent,
    FilterComponent,
    ApplicationComponent,
    LandingComponent,
    LayoutRamonComponent,
    ListadoCotizacionesComponent,
    LoginAplicacionComponent,
    ParametrosComponent,
    FiltroAptosPipe,
    FiltroOtrasUnidades
  ],
  imports: [
    BrowserModule,
    routing,
    HttpModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes, { useHash: false }),
    SharedModule
  ],
  providers: [
    appRoutingProviders,
    SessionGuard,
    AdminGuard,
    LoginGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
