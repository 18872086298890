import {Pipe, PipeTransform} from '@angular/core';
import {Units} from '../models/units';

@Pipe({
  name: 'filtroOtrasUnidades'
})

export class FiltroOtrasUnidades implements PipeTransform {
  // passing another argument
  public parks: Units[];
  public depo: Units[];
  public groupUnits: Units[];

  constructor() {
    this.parks = [];
    this.groupUnits = [];
  }

  transform(items: Units[]): any {
    items.filter(item => item.idTipoUnidad != 63);
    this.groupUnits = this.parks.concat(
      new Units(null, null, '', 'PARS', null, null, null, null, '', '', null, null, '', '', ''),
      items.filter(item => item.idTipoUnidad == 77),
      new Units(null, null, '', 'DEPO', null, null, null, null, '', '', null, null, '', '', ''),
      items.filter(item => item.idTipoUnidad == 71),
      new Units(null, null, '', 'COM', null, null, null, null, '', '', null, null, '', '', ''),
      items.filter(item => item.idTipoUnidad == 91)
    );

    if (items.filter(item => item.idTipoUnidad == 77).length == 0) {
      this.groupUnits = this.groupUnits.filter(p => p.nomenclatura != 'PARS');
    }
    if (items.filter(item => item.idTipoUnidad == 71).length == 0) {
      this.groupUnits = this.groupUnits.filter(p => p.nomenclatura != 'DEPO');
    }
    if (items.filter(item => item.idTipoUnidad == 91).length == 0) {
      this.groupUnits = this.groupUnits.filter(p => p.nomenclatura != 'COM');
    }
    return this.groupUnits;
  }
}
