import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { AdminLayoutComponent } from './admin_layout/adminLayout.component';
import { MainHeaderAdminComponent } from './main_header_admin/mainHeaderAdmin.component';
import { MainContentComponent } from './main_content/mainContent.component';

@NgModule({
  imports: [RouterModule],
  declarations: [AdminLayoutComponent, MainHeaderAdminComponent, MainContentComponent],
  exports: [AdminLayoutComponent, MainHeaderAdminComponent, MainContentComponent]
})
export class ThemesModule { }