import {Component, EventEmitter, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-credit-year',
  templateUrl: './credit-year.component.html',
  styleUrls: ['./credit-year.component.css']
})
export class CreditYearComponent implements OnInit {

  @Output() getCreditDataYear = new EventEmitter<any>();
  public years: Array<any>;

  constructor() { }

  ngOnInit() {
    this.years = JSON.parse(sessionStorage.getItem('creditParameters'));
  }

  onSelectYear(value) {
    const selected = this.years.find((item) => item.id == value);
    this.getCreditDataYear.emit(selected);
  }

}
