import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import { CurrencyService } from '../../services/currency.service';
import { currencies } from '../lead/constants';

@Component({
  selector: 'app-currency',
  templateUrl: './currency.component.html',
  styleUrls: ['./currency.component.css'],
})
export class CurrencyComponent implements OnInit {

  @Output() getCurrentCurrency = new EventEmitter<any>();

  public currency: any = null;
  public currencies: Array<any>;
  public currentCurrency: any;
  public disabled: boolean = false;
  public date: string;

  constructor(private currencyService: CurrencyService) {
    this.currencies = currencies;
    this.date = this.currentDate();
  }

  ngOnInit(): void {
    this.getCurrency();
  }

  public currentDate() {
    const date = new Date();
	  return `${date.getDate()}/${(date.getMonth()+1)}/${date.getFullYear()}`;
  }

  getCurrency(): void {
    this.currencyService.getCurrency().subscribe(
      currency => this.currency = currency,
      error => {},
      () => {
        this.disabled = this.currency ? false : true;
      }
    );
  }

  public onSelectCurrency(value) {
    this.currentCurrency = { code: value, exchange: Number(this.currency[value]) }
    this.getCurrentCurrency.emit(this.currentCurrency);
  }

}
