export class User {
  constructor(
    public id: number,
    public primerNombre: string,
    public segundoNombre: string,
    public primerApellido: string,
    public segundoApellido: string,
    public cedula: number,
    public telefono: number,
    public email: string,
    public type_document: string,
    public module: string
  ) {
  }
}
