import { Component, OnInit } from '@angular/core';
import { User } from '../shared/models/user';
import { Router } from '@angular/router';
declare var jquery:any;
declare var $:any;
@Component({
  templateUrl: '../views/application.component.html'
})

export class ApplicationComponent implements OnInit{
  public user: User;
  public id:number;
  public name:string;
  public alert:string;

  constructor(public _ar: Router){
    this.user = new User(0, '', '', '', '', 0, 0, '', '', '');
  }
  public ngOnInit(){
    this.user = JSON.parse(sessionStorage.getItem('user'));
    this.id   = this.user.id;
  }
};
