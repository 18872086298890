import {Injectable} from '@angular/core';
import {Http, Headers, RequestOptions} from '@angular/http';
import {map} from 'rxjs/operators';
import {environment} from '../../../environments/environment';

@Injectable()

export class DataService {

  constructor(private _http: Http) {}

  /**
   * GET owners of projects JSON file
   */
  public getOwnersService(): any {
    return this._http.get(environment.urlAsesores).pipe(map(res => res.json()));
  }

  /**
   * GET owners of projects JSON file
   */
  public getAdvertisingMediumService(): any {
    return this._http.get('assets/data/medio-publicitario-listado.json').pipe(map(res => res.json()));
  }
}
