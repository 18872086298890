import {Injectable} from '@angular/core';
import {Http, Headers, RequestOptions} from '@angular/http';
import {Parametros} from '../models/parametros';
import {map} from 'rxjs/operators';
import {Concepto} from '../models/concepto';
import {Cotizacion} from '../models/cotizacion';
import {environment} from 'src/environments/environment';

@Injectable()

export class ApiService {
  public url: string;
  public options: any;

  constructor(private _http: Http) {
    this.options = this.conectionConfig();
    this.url = environment.apiCotizador + '/api/';
  }

  public generarPdfService(cotizacion): any {
    return this._http.post(this.url + 'quotations/pdf', cotizacion, this.options).pipe(map(res => res.json()));
  }

  public obtenerCotizaciones(idZoho: number): any {
    return this._http.get(this.url + `quotationszoho?id_zoho=${idZoho}`, this.options).pipe(map(res => res.json()));
  }

  private conectionConfig(proceso: number = 1): RequestOptions {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('cache-control', 'no-cache');
    if (proceso !== 1) {
      headers.append('Authorization', 'Bearer ' + localStorage.getItem('ta'));
    }
    const options = new RequestOptions({headers: headers});
    return options;
  }

  public getParameters(): any {
    const petitionOptions: RequestOptions = this.conectionConfig();
    return this._http.get(this.url + 'parameters', petitionOptions)
      .pipe(map(res => res.json()));
  }

  public guardarParametrosService(parametros: Array<Parametros>): any {
    const options = this.conectionConfig(2);
    const jsonData = JSON.stringify(parametros);
    return this._http.post(this.url + 'parameters/update/multi', jsonData, options).pipe(map(res => res.json()));
  }

  public validarUsuarioService(usuario: object): any {
    const jsonData = JSON.stringify(usuario);
    return this._http.post(this.url + 'login', jsonData, this.options).pipe(map(res => res.json()));
  }

  /**
   * obtiene todos los conceptos
   */
  public obtenerTodosConceptos(): any {
    const options = this.conectionConfig(2);
    return this._http.get(this.url + 'concepts', options).pipe(map(res => res.json()));
  }

  /**
   * obtiene los conceptos
   */
  public obtenerConceptos(): any {
    const options = this.conectionConfig(2);
    return this._http.get(this.url + 'concepts/calculator', options).pipe(map(res => res.json()));
  }

  /**
   * Crear concepto
   * @param concepto
   */
  public crearConcepto(concepto: Concepto): any {
    const options = this.conectionConfig(2);
    return this._http.post(this.url + 'concepts', concepto, options).pipe(map(res => res.json()));
  }

  /**
   * Actualizar concepto
   * @param concepto
   */
  public actualizarConcepto(concepto: Concepto): any {
    const options = this.conectionConfig(2);
    return this._http.put(this.url + 'concepts/' + concepto.id, concepto, options).pipe(map(res => res.json()));
  }

  /**
   * Consultar concepto por ID
   * @param idConcepto
   */
  public obtenerConceptoId(idConcepto: number): any {
    const options = this.conectionConfig(2);
    return this._http.get(this.url + 'concepts/' + idConcepto, options).pipe(map(res => res.json()));
  }

  /**
   * Get active years Credit
   *
   */
  public getActiveYearCredit(): any {
    return this._http.get(`${this.url}credit-years/active-years`).pipe(map(res => res.json()));
  }

}
